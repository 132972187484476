<template>
  <v-dialog v-model="shower" scrollable persistent max-width="500px">
    <v-card v-if="status_response === 'OK'">
      <v-card-text class="pa-1 pt-1 pb-1 text-center" style="height:200px">
        <h2 style="fornt-size:30; color:#184966;" class="pa-1 pt-6 pb-1">เปลี่ยนแปลงแพ็คเกจสำเร็จ</h2>
        <p style="fornt-size:35;" class="pa-1 pt-4 pb-1 text--primary text-center">ระบบจะทำการปรับพื้นที่และคิดค่าบริการ</p>
        <p style="fornt-size:35;" class="pa-0 pt-0 pb-0 text--primary text-center">แพ็คเก็จใหม่ในรอบเดือนถัดไป</p>
      </v-card-text>
      <v-card-actions class="text-center pa-4">
        <v-spacer></v-spacer>
        <v-btn :color="color.theme" class="pa-0 pt-0 white--text" @click="$emit('closedialog')">{{
          $t("changeName.submit")
        }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-text class="pa-1 pt-1 pb-1 text-center" style="height:200px">
        <h2 style="fornt-size:30; color:red;" class="pa-1 pt-6 pb-1">เปลี่ยนแปลงแพ็คเกจไม่สำเร็จ</h2>
        <p style="fornt-size:35;" class="pa-1 pt-4 pb-1 text--primary text-center">เปลี่ยนแปลงแพ็คเกจไม่สำเร็จ</p>
        <p style="fornt-size:35;" class="pa-0 pt-0 pb-0 text--primary text-center">เนื่องจากคุณใช้งานเกินแพ็คเกจใหม่ที่เลือก</p>
      </v-card-text>
      <v-card-actions class="text-center pa-4">
        <v-spacer></v-spacer>
        <v-btn :color="color.theme" class="pa-0 pt-0 white--text" @click="$emit('closedialog')">{{
          $t("changeName.submit")
        }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  props: ["show","status_response"],
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          console.log(this.status_response);
        }
        return this.show;
      },
    },
  },
};
</script>
