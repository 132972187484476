<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="700px">
      <v-card>
        <v-card-title>
          <h3 class="text-h5"> รายละเอียดแพ็กเกจ</h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height:600px">
          <v-container>
            <!-- <div v-if="loaddataprogress === true" class="text-center">
              <v-progress-circular indeterminate size="64" :color="color.theme">
                <span>&nbsp; &nbsp; loading</span>
              </v-progress-circular>
            </div> -->
            <v-flex lg12 xs12 class="mr-4 ml-4">
              <h2 class="text-left mt-8" style="color:#000000; fornt-size:18;"></h2>
              <v-card class="mx-auto mt-8" outlined id="borderradius" v-if="execution !== 'R'">
                <!-- <h3 class="pa-2">แพ็กเกจปัจจุบัน</h3>
                <v-divider></v-divider>
                <v-list three-line>
                  <template>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>แพ็กเก็จหลัก</v-list-item-title>
                        <v-list-item-subtitle>{{old_package_main_name}}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{old_package_main_storage + ' ' + old_package_main_user + ' User'}}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-title style="color:#000000; fornt-size:25;">
                          {{numberWithCommas(old_package_main_price)}}</v-list-item-title
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <template v-if="new_list_oldpackage.length != 0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>แพ็กเก็จเสริม</v-list-item-title>
                        <v-list-item-subtitle v-for="(item, id) in new_list_oldpackage" :key="id">{{
                          item.package_name + " x " + item.package_num_package + " , "
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-title class="pt-4" v-for="(item, id) in new_list_oldpackage" :key="id" style="color:#000000; fornt-size:25;">
                          {{ numberWithCommas(item.package_price_sum) + " " + "บาท" }}</v-list-item-title
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>          -->
                <!-- <v-divider></v-divider> -->
                <h3 class="pa-2">ซื้อแพ็กเก็จ</h3>
                <v-divider></v-divider>
                <v-list three-line>
                  <template v-if="listpackageupdate_detail_update_main.length != 0">
                    <v-list-item>
                      <v-list-item-content>
                         <!-- <v-list-item-title>แพ็กเก็จหลัก</v-list-item-title> -->
                         <h3>แพ็กเก็จหลัก</h3>
                        <v-list-item-subtitle >{{ listpackageupdate_detail_update_main[0].package_name }}</v-list-item-subtitle>
                         <v-list-item-subtitle>{{listpackageupdate_detail_update_main[0].package_storage + ' ' + listpackageupdate_detail_update_main[0].package_number_user + ' User'}}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="pt-2">
                        <v-list-item-title style="color:#000000; fornt-size:25;">
                          {{ numberWithCommas(listpackageupdate_detail_update_main[0].amount) + " " + "บาท" }}</v-list-item-title
                        >
                      </v-list-item-action>
                      
                    </v-list-item>
                  </template>
                  <v-divider v-if="listpackageupdate_detail_update_main.length != 0"></v-divider> 
                  <template v-if="listpackageupdate_detail_update_addon.length != 0">
                    <v-list-item>
                      <v-list-item-content>
                        <!-- <v-list-item-title>แพ็กเก็จเสริม</v-list-item-title> -->
                        <h3>แพ็กเก็จเสริม</h3>
                        <v-list-item-subtitle v-for="(item, id) in listpackageupdate_detail_update_addon" :key="id">{{
                          item.package_name_en + " x " + item.quantity_add + " , "
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="pt-2">
                         <v-list-item-subtitle class="pt-2" v-for="(item, id) in listpackageupdate_detail_update_addon" :key="id" style="color:#000000; fornt-size:25;">
                          {{ numberWithCommas(item.amount) + " " + "บาท" }}</v-list-item-subtitle
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </template>

                <!-- <v-divider></v-divider>
                <h3 class="pa-2">แพ็กเกจที่มีการอัปเดต</h3>
                <v-divider></v-divider>
                <v-list three-line>
                  <template v-if="listpackageupdate_detail_update_main.length !== 0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>แพ็กเก็จหลัก</v-list-item-title>
                        <v-list-item-subtitle v-for="(item, id) in listpackageupdate_detail_update_main" :key="id">{{
                          item.package_num_package != 0 ? item.package_name + " * " + item.quantity_add + " , " : ""
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="pt-6">
                        <v-list-item-title class="pt-2" v-for="(item, id) in listpackageupdate_detail_update_main" :key="id" style="color:#000000; fornt-size:25;">
                          {{ numberWithCommas(item.amount) + " " + "บาท" }}</v-list-item-title
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <template v-if="listpackageupdate_detail_update_addon.length !== 0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>แพ็กเก็จเสริม</v-list-item-title>
                        <v-list-item-subtitle v-for="(item, id) in listpackageupdate_detail_update_addon" :key="id">{{
                          item.package_num_package != 0 ? item.package_name + " * " + item.quantity_add + " , " : ""
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="pt-6">
                        <v-list-item-subtitle class="pt-2" v-for="(item, id) in listpackageupdate_detail_update_addon" :key="id" style="color:#000000; fornt-size:25;">
                          {{ numberWithCommas(item.amount) + " " + "บาท" }}</v-list-item-subtitle
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                    <v-divider v-if="listpackageupdate_detail_update_addon.length !== 0 && listpackageupdate_detail_r.length !== 0"></v-divider>
                    <template v-if="listpackageupdate_detail_r.length !== 0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>ลดแพ็กเก็จเสริม</v-list-item-title>
                        <v-list-item-subtitle v-for="(item, id) in listpackageupdate_detail_r" :key="id">{{
                          item.package_num_package != 0 ? item.package_name + " * " + item.quantity_add + " , " : ""
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template> -->
                  <!-- <template>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>แพ็กเก็จหลัก</v-list-item-title>
                        <v-list-item-subtitle>{{ list_selected_package_main_show[0].namepackage_th }}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{
                          list_selected_package_main_show[0].storage + " | " + list_selected_package_main_show[0].user + " User"
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-title style="color:#000000; fornt-size:25;">
                          {{ numberWithCommas(list_selected_package_main_show[0].price) + " บาท" }}</v-list-item-title
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </template> -->
                  <!-- <template>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>แพ็กเก็จเสริม</v-list-item-title>
                        <v-list-item-subtitle v-for="(item, id) in listpackage_addon_" :key="id">{{
                          item.package_num_package != 0 ? item.package_name + " * " + item.package_num_package + " , " : ""
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-title style="color:#000000; fornt-size:25;">
                          {{ numberWithCommas(pricetotal_addon_) + " " + "บาท" }}</v-list-item-title
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </template> -->
                <!-- </v-list> -->
                  <v-divider></v-divider>
                  <template>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>รวม</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>{{ numberWithCommas(total_amount) + " " + "บาท" }}</v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>VAT 7%</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>{{ numberWithCommas(vat_) + " " + "บาท" }}</v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>รวมยอดชำระ</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>{{ numberWithCommas(total_amount_vat) + " " + "บาท" }}</v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
                
              </v-card>
              <v-card class="mx-auto mt-8" outlined id="borderradius" v-else>
                <h3 class="pa-2">ยกเลิกแพ็กเก็จ</h3>
                <v-divider></v-divider>
                <v-list three-line>
                  <template v-if="listpackageupdate_detail_r_main.length != 0">
                    <v-list-item>
                      <v-list-item-content>
                         <h3>แพ็กเก็จหลัก</h3>
                        <v-list-item-subtitle >{{ listpackageupdate_detail_r_main[0].package_name }}</v-list-item-subtitle>
                         <v-list-item-subtitle>{{listpackageupdate_detail_r_main[0].package_storage + ' ' + listpackageupdate_detail_r_main[0].package_number_user + ' User'}}</v-list-item-subtitle>
                      </v-list-item-content>
                      <!-- <v-list-item-action class="pt-2">
                        <v-list-item-title style="color:#000000; fornt-size:25;">
                          {{ numberWithCommas(listpackageupdate_detail_r_main[0].amount) + " " + "บาท" }}</v-list-item-title
                        >
                      </v-list-item-action> -->
                    </v-list-item>
                  </template>
                  <v-divider v-if="listpackageupdate_detail_r_main.length != 0"></v-divider> 
                  <template>
                    <v-list-item>
                      <v-list-item-content>
                        <!-- <v-list-item-title>แพ็กเก็จเสริม</v-list-item-title> -->
                        <h3>แพ็กเก็จเสริม</h3>
                        <v-list-item-subtitle v-for="(item, id) in listpackageupdate_detail_r_addon" :key="id">{{
                          item.package_name_en + " x " + item.quantity_add + " , "
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <!-- <v-list-item-action class="pt-2">
                         <v-list-item-subtitle class="pt-2" v-for="(item, id) in listpackageupdate_detail_r" :key="id" style="color:#000000; fornt-size:25;">
                          {{ numberWithCommas(item.amount) + " " + "บาท" }}</v-list-item-subtitle
                        >
                      </v-list-item-action> -->
                    </v-list-item>
                  </template>

                <!-- <v-divider></v-divider>
                <h3 class="pa-2">แพ็กเกจที่มีการอัปเดต</h3>
                <v-divider></v-divider>
                <v-list three-line>
                  <template v-if="listpackageupdate_detail_update_main.length !== 0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>แพ็กเก็จหลัก</v-list-item-title>
                        <v-list-item-subtitle v-for="(item, id) in listpackageupdate_detail_update_main" :key="id">{{
                          item.package_num_package != 0 ? item.package_name + " * " + item.quantity_add + " , " : ""
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="pt-6">
                        <v-list-item-title class="pt-2" v-for="(item, id) in listpackageupdate_detail_update_main" :key="id" style="color:#000000; fornt-size:25;">
                          {{ numberWithCommas(item.amount) + " " + "บาท" }}</v-list-item-title
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <template v-if="listpackageupdate_detail_update_addon.length !== 0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>แพ็กเก็จเสริม</v-list-item-title>
                        <v-list-item-subtitle v-for="(item, id) in listpackageupdate_detail_update_addon" :key="id">{{
                          item.package_num_package != 0 ? item.package_name + " * " + item.quantity_add + " , " : ""
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="pt-6">
                        <v-list-item-subtitle class="pt-2" v-for="(item, id) in listpackageupdate_detail_update_addon" :key="id" style="color:#000000; fornt-size:25;">
                          {{ numberWithCommas(item.amount) + " " + "บาท" }}</v-list-item-subtitle
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                    <v-divider v-if="listpackageupdate_detail_update_addon.length !== 0 && listpackageupdate_detail_r.length !== 0"></v-divider>
                    <template v-if="listpackageupdate_detail_r.length !== 0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>ลดแพ็กเก็จเสริม</v-list-item-title>
                        <v-list-item-subtitle v-for="(item, id) in listpackageupdate_detail_r" :key="id">{{
                          item.package_num_package != 0 ? item.package_name + " * " + item.quantity_add + " , " : ""
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template> -->
                  <!-- <template>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>แพ็กเก็จหลัก</v-list-item-title>
                        <v-list-item-subtitle>{{ list_selected_package_main_show[0].namepackage_th }}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{
                          list_selected_package_main_show[0].storage + " | " + list_selected_package_main_show[0].user + " User"
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-title style="color:#000000; fornt-size:25;">
                          {{ numberWithCommas(list_selected_package_main_show[0].price) + " บาท" }}</v-list-item-title
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </template> -->
                  <!-- <template>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>แพ็กเก็จเสริม</v-list-item-title>
                        <v-list-item-subtitle v-for="(item, id) in listpackage_addon_" :key="id">{{
                          item.package_num_package != 0 ? item.package_name + " * " + item.package_num_package + " , " : ""
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-title style="color:#000000; fornt-size:25;">
                          {{ numberWithCommas(pricetotal_addon_) + " " + "บาท" }}</v-list-item-title
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </template> -->
                <!-- </v-list> -->
                  <!-- <v-divider></v-divider>
                  <template>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>รวม</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>{{ numberWithCommas(total_amount) + " " + "บาท" }}</v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>VAT 7%</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>{{ numberWithCommas(vat_) + " " + "บาท" }}</v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>รวมยอดชำระ</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>{{ numberWithCommas(total_amount_vat) + " " + "บาท" }}</v-list-item-action>
                    </v-list-item>
                  </template> -->
                </v-list>
                
              </v-card>

            </v-flex>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
          <v-progress-linear indeterminate :color="color.theme" v-if="loaddataprogress === true"></v-progress-linear>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color="color.theme" outlined @click="$emit('closedialog')">
            ปิด
          </v-btn>
          <v-btn class="white--text" :color="color.theme" :disabled="loaddataprogress === true" @click="fn_updatepackage()" >
            ยืนยัน
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialogconfirmupdate
      :show="opendialogconfirmupdatepackage"
      :status_response="status_response"
      @closedialog="opendialogconfirmupdatepackage = false,$emit('reload')"
    ></dialogconfirmupdate>
    <tax_invoice
      :show="opentaxinvoice"
      :checktaxbtn="checktaxbtn"
      @closedialog="(opentaxinvoice = false), fn_check_tax_invoice(), (checktaxbtn = 0)"
      @payment="(opentaxinvoice = false), fn_buypackage(), (checktaxbtn = 0)"
    ></tax_invoice>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import dialogconfirmupdate from "../optional/dialog-confirmupdatepackage.vue";
import tax_invoice from "../optional/dialog-tax-invoice";
// const dialogconfirmupdate = () => import("../components/optional/dialog-confirmupdatepackage.vue");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  data: function() {
    return {
      checktaxbtn: 0,
      opentaxinvoice:false,
      URL_payment:'',
      status_response:'',
      old_package_main_name:'',
      old_package_main_price:0,
      old_package_main_storage:'',
      old_package_main_user:0,
      loaddataprogress: false,
      opendialogconfirmupdatepackage: false,
      processdelete: false,
      new_listpackage_current_addon_: [],
      new_list_oldpackage: [],
      pricetotal_addon_old: 0,
    };
  },
  components: { dialogconfirmupdate,tax_invoice },
  props: [
    "show",
    "detail",
    "detail_main",
    "detail_addon",
    "all_name",
    "paid_dtm",
    "vat_",
    "total_amount",
    "total_amount_vat",
    "listpackage_current_main_",
    "listpackage_current_addon_",
    "pricetotal_addon_",
    "listpackage_addon_",
    "listpackage_addon_show_Update",
    "buy_package_main",
    "buy_package_addon",
    "list_selected_package_main_show",
    "execution",
    "listpackageupdate_detail_update_addon",
    "listpackageupdate_detail_update_main",
    "listpackageupdate_detail_r",
    "listpackageupdate_detail_r_addon",
    "listpackageupdate_detail_r_main",
    "taxInvoice"
  ],
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    shower: {
      get() {
        if (this.show === true) {
          console.log("----",this.taxInvoice);
          this.new_list_oldpackage = [];
          // console.log("detail", this.detail);
          console.log("แพ็ตเกจหลักเดิม", this.listpackage_current_main_);
          // console.log("แพ็กเกจเสริมเดิม", this.listpackage_current_addon_);
          // console.log(this.listpackage_addon_);
          // console.log("listpackage_addon_show_Update", this.listpackage_addon_show_Update);
          // console.log("list_selected_package_main_show",this.list_selected_package_main_show);

          // this.new_listpackage_current_addon_ = this.listpackage_current_addon_
          this.old_package_main_name = this.listpackage_current_main_[0].package_name_th
          this.old_package_main_price = this.listpackage_current_main_[0].package_price
          this.old_package_main_storage = this.listpackage_current_main_[0].package_storage
          this.old_package_main_user = this.listpackage_current_main_[0].number_user
          for (var i = 0; i < this.listpackage_addon_show_Update.length; i++) {
            if (this.listpackage_addon_show_Update[i].package_num_package != 0) {
              this.new_list_oldpackage.push(this.listpackage_addon_show_Update[i]);
            }
          }
          this.pricetotal_addon_old = this.new_list_oldpackage.reduce((a, b) => a + b.package_price_sum, 0);
          // console.log("====", this.new_list_oldpackage);
        }
        return this.show;
      },
    },
  },
  methods: {
    fn_buypackage(){
       this.$emit('payment');
    },
     fn_check_tax_invoice() {
      // console.log("fn_check_tax_invoice");
      let payload = {
        business_id: this.dataAccountActive.business_info.business_id,
      };
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/get_taxinvoic_address", payload)
        .then((response) => {
          if (response.data.status === "OK") {
            this.taxInvoice = true;
          } else {
            this.taxInvoice = false;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
     },
    // test(){
    //   // this.$emit("closedialog");
    //   this.opendialogconfirmupdatepackage = true;
    // },
    fn_updatepackage() {
      console.log("----",this.taxInvoice);
      if(this.taxInvoice === true){      
        this.status_response = '';
        let payload = {
          account_id: this.dataAccountId,
          data_id: this.dataAccountActive.type === 'Citizen' ? this.dataAccountId : this.dataAccountActive.business_info.business_id,
          type: this.dataAccountActive.type === 'Citizen' ? 'citizen': 'business',
          order_desc: "",
          list_packge_main: this.buy_package_main,
          list_packge_addon: this.buy_package_addon,
          execution:this.execution,
          type_invoice:"Y"
        };
        console.log("payload", payload);
        this.loaddataprogress = true;
        //  let auth = await gbfGenerate.generateToken();
        this.axios.post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/update_payment_package_storage", payload).then((response) => {
          if (response.data.status === "OK") {
            console.log("response", response);
            this.status_response = 'OK';
            this.loaddataprogress = false;
            this.$emit("closedialog");
            if(this.execution === 'R'){
              this.opendialogconfirmupdatepackage = true;
            }else{
              this.URL_payment = response.data.result
              this.gotopay();
              // this.$emit("buy_addon");
            }
            // this.$emit("reload");
          } else {
            this.status_response = 'ERROR';
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
      }else{
        this.checktaxbtn = 1;
        this.opentaxinvoice =  true;
      }
    },
    gotopay() {
      // window.open(this.URL_payment, "_blank");
      window.open(this.URL_payment, "_self");
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
};
</script>
<style></style>
